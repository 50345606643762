const extendsDictionary = (dist, src) => {
  for (let key in src) {
    if (!Object.prototype.hasOwnProperty.call(src, key)) continue
    dist[key] = src[key]
  }
  return dist
}

class SynapseStore {
  constructor () {
    this._store = []
  }

  get list () { return this._store }

  synapseAt (idx) { return this._store[idx] }
  push (synapse) { this._store.push(synapse) }
  pushAll (list) { list.forEach(s => this.push(s)) }

  find (serial) { return this._store.filter(s => s.serial === serial) }
  findOne (serial) { return this._store.filter(s => s.serial === serial).shift() }
  update (synapse) {
    const existing = this.findOne(synapse.serial)
    if (existing) {
      extendsDictionary(existing, synapse)
    } else {
      this._store.push(synapse)
    }
  }
  remove (serial) {
    const existing = this.findOne(serial)
    if (existing) {
      this._store.splice(this._store.indexOf(existing), 1)
    }
  }
  clear () {
    while (this._store.length > 0) {
      this._store.pop()
    }
  }
  reload() {}
}

class ArraySynapseStore extends SynapseStore {
  constructor (array) {
    super()
    if (array) {
      this.pushAll(array)
    }
  }
}

class APISynapseStore extends SynapseStore {
  constructor (api) {
    super()
    this.$api = api
  }

  get list () { return this._store.map(r => r.body) }

  synapseAt (idx) { return this._store[idx] }
  async push (synapse) {
    const res = await this.$api.upsert({entity: 'synapse', body: synapse})
    this._store.push(res)
  }
  pushAll (list) { list.forEach(s => this.push(s)) }

  find (serial) { return this._store.filter(s => s.body.serial === serial) }
  findOne (serial) { return this._store.filter(s => s.body.serial === serial).shift() }
  update (synapse) {
    const existing = this.findOne(synapse.serial)
    const req = {entity: 'synapse', body: synapse}
    if (existing) req['id'] = existing.id
    this.$api.upsert(req)
  }
  async remove (serial) {
    const existing = this.findOne(serial)
    if (existing) {
      await this.$api.delete(existing.id)
      this._store.splice(this._store.indexOf(existing), 1)
    }
  }
  clear () {
    while (this._store.length > 0) {
      this._store.pop()
    }
  }
  async reload () {
    const res = await this.$api.find({entity: 'synapse', contains: {}})
    this._store = res
  }
}

export { SynapseStore, ArraySynapseStore, APISynapseStore }
