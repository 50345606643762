import components from './ui'
import { MODE_MAP } from './constants'

class SynapseModel {
  constructor (serial) {
    this.serial = serial || ''
    this.version = '0'
    this.extra = {}
    this.initPins()
  }

  initPins () {
    this.pins = [...Array(SynapseModel.getNumPins())].map((_, i) => ({
      id: i,
      mode: 0,
      value: 0,
      modeArray: []
    }))
  }

  static getNumPins () { return 12 }

  static fromMessage (m) {
    const result = new SynapseModel()
    result.serial = m.sn
    result.version = m.ver
    result.pins = SynapseModel.parsePinsMessage(m.pins)
    return result
  }

  static parsePinsMessage(pins) {
    const convertBase64ToHexArray = (b64) => {
      const result = []
      const bytes = atob(b64)
      for (let i = 0; i < bytes.length; i++) {
        result.push(bytes.charCodeAt(i))
      }
      return result
    }
    const bytes = convertBase64ToHexArray(pins)
    const result = []
    for (let i = 0; i < bytes.length; i += 2) {
      const p = bytes[i + 1] << 8 | bytes[i]
      result.push({
        id: i / 2,
        mode: p >> 12,
        modeArray: SynapseModel.buildModeArray(p >> 12),
        value: p & 0x0FFF
      })
    }
    return result
  }

  static buildModeArray (mode) {
    const result = []
    Object.keys(MODE_MAP).forEach((key) => {
      if ((mode & key) == key) {
        result.push(MODE_MAP[key])
      }
    })
    return result   
  }

  static buildPinsWith (synapse) {
    const convertArrayToBase64 = (array) => btoa(array.map((v) => String.fromCharCode(v)).join(''))
    const findUI = id => Object.values(synapse.extra.ui).find((p) => p.id === id)
    return convertArrayToBase64(synapse.pins.reduce((a, p) => {
      const ui = findUI(p.id)
      if (!ui) return a = a.concat([0, 0])
      const component = components[ui.type]
      const v = component.mode << 12 | p.value
      return a = a.concat([v & 0x00ff, v >> 8])
    }, []))
  }
}

export { SynapseModel }
