class Communicator {
  constructor(key, url) {
    this.key = key
    this.url = url
    this.onceCallbacks = {}
    this.connected = false
  }

  connect(url) {
    return new Promise(resolve => {
      this.socket = new WebSocket(url)
      this.socket.onmessage = ({data}) => this.onMessage(data)
      this.socket.onopen = () => {
        this.connected = true
        resolve()
      }
    })
  }

  async validateSocket () {
    if (this.connected) return
    if (this.socket) {
      await new Promise(resolve => {
        const interval = setInterval(() => {
          if (this.connected) {
            clearInterval(interval)
            resolve()
          }
        }, 100)
      })
    }
    await this.connect(this.url)
  }

  once (event, callback) {
    this.onceCallbacks[event] = callback
  }

  onMessage(data) {
    console.log('onMessage', data)
    if (this.onceCallbacks['message']) {
      this.onceCallbacks['message'](data)
      delete this.onceCallbacks['message']
    }
  }

  async send(data) {
    await this.validateSocket()
    console.log('send', data)
    this.socket.send(JSON.stringify(data))
  }

  auth(token) {
    return new Promise(resolve => {
      this.once('message', data => {
        resolve(data)
      })
      this.send({cmd: 'auth', params: {token}})
    })
  }

  bind(signedSerial) {
    return this.send({cmd: 'bind', params: {key: this.key, signedSerial}})
  }

  update(signedSerial, message) {
    console.log('update', JSON.stringify(message))
    return this.send({cmd: 'update', params: {key: this.key, signedSerial, message}})
  }
}

export default Communicator