import { createWebHistory, createRouter } from 'vue-router'
import MainComponent from '@/components/MainComponent'
import LoginComponent from '@/components/login'

export default createRouter({
  history: createWebHistory(),
  routes: [
    { path: '/', name: 'MainComponent', component: MainComponent },
    { path: '/login', name: 'LoginComponent', component: LoginComponent }
  ]
})
