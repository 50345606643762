import 'quasar/dist/quasar.css'
import iconSet from 'quasar/icon-set/material-icons.js'
import '@quasar/extras/material-icons/material-icons.css'
import Loading from 'quasar/src/plugins/Loading.js';import Dialog from 'quasar/src/plugins/Dialog.js';

export default {
  config: { loading: { /* Loading defaults */ } },
  plugins: {
    Loading, Dialog
  },
  iconSet: iconSet
}