import { createApp } from 'vue'
import App from './App'
import router from './router'
import Quasar from 'quasar/src/vue-plugin.js';
import quasarUserOptions from './quasar-user-options'
import Vue3TouchEvents from "vue3-touch-events"
import API from '@winm2m/argos-api'
import Communicator from './components/argos/communicator'

const SECRET_KEYCODE = 1
const BACKEND_HOST = 'worker-app0.winm2m.com'
const API_PREFIX = `https://${BACKEND_HOST}`
const API_VERSION = '0.2.4'

const app = createApp(App).use(Quasar, quasarUserOptions)
app.config.globalProperties.$api = new API(API_PREFIX, API_VERSION)
app.config.globalProperties.$communicator = new Communicator(SECRET_KEYCODE, `wss://${BACKEND_HOST}/api/v${API_VERSION}/argos/open/connect`)
app.use(Vue3TouchEvents)
app.use(router).mount('#app')