import PushComponent from './push'
import SliderComponent from './slider'
import GaugeComponent from './gauge'
import ToggleComponent from './toggle'
import LedComponent from './led'

const components = {
  push: PushComponent,
  slider: SliderComponent,
  gauge: GaugeComponent,
  toggle: ToggleComponent,
  led: LedComponent
}

export default components
