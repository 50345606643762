export default {
  mode: 0,
  computed: {
    value: {
      get () {
        return (this.getPin() || {}).value
      },
      set (value) {
        this.$emit('update', value)
      }
    }
  },  
  props: {
    ui: {
      type: Object,
      required: true
    },
    synapse: {
      type: Object,
      required: true
    }
  },
  methods: {
    getPin () {
      return this.synapse.pins.filter(p => p.id === this.ui.id).shift()
    }
  }
}