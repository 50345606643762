import useQuasar from 'quasar/src/composables/use-quasar.js';
import { SynapseModel } from '../synapse'

export default {
  data: () => ({
    store: null,
    selected: {}
  }),
  created () {
    this.$q = useQuasar()
  },
  async mounted () {
    this.store = await this.createStore()
    this.reload()
  },
  methods: {
    async createStore () { return null },
    async reload () {
      this.$q.loading.show()
      await this.store.reload()
      this.$q.loading.hide()
    },
    onUpdated ({ type, params }) {
      const storableTypes = [ 'delete', 'add', 'nick', 'layout' ]
      this.updateUI({ type, params })
      if (storableTypes.includes(type))
        this.store.update(this.selected)
      this.updatePins(this.selected)
    },
    updateUI ({type, params}) {
      const funcs = {
        delete: () => {
          this.selected.extra.ui = this.selected.extra.ui.filter(ui => ui.id !== params.ui.id)
        },
        add: () => {
          this.selected.extra.ui.push(params.ui)
        },
        nick: () => {
          this.selected.extra.nick = params.nick
        },
        layout: () => {
          this.selected.extra.ui.forEach(u => {
            const l = params.layout.find(l => l.id === u.id)
            u.top = l.top
            u.left = l.left
          })
        },
        value: () => {
          const pin = this.selected.pins.find(p => p.id === params.id)
          pin.value = params.value
        }
      }
      this.selected.extra = (this.selected.extra || {})
      this.selected.extra.ui = (this.selected.extra.ui || [])
      funcs[type]()
    },
    async updatePins(synapse) {
      this.$communicator.update(synapse.serial, [{key: 'pins', value: SynapseModel.buildPinsWith(synapse)}])
    },
    onRequestEditable () {
      this.selected.editable = true
    },
    onResolveEditable () {
      this.selected.editable = false
    },
    setSelected (synapse) {
      this.selected = synapse
      this.selected.editable = false
    },
    onAdd () {
      this.$q.dialog({
        title: 'Add Synapse',
        message: 'Enter serial number',
        prompt: {
          model: '',
          type: 'text' // optional
        },
        cancel: true,
        persistent: true
      }).onOk(serial => {
        this.addSynapseWith(serial)
      })      
    },
    async addSynapseWith (serial) {
      this.$root.withLoading(() => this.store.push(new SynapseModel(serial)))
    },
    onDelete (synapse) {
      this.$q.dialog(
        {
          title: 'Delete Synapse',
          message: `Are you sure you want to delete ${synapse.serial}?`,
          cancel: true,
          persistent: true
        }
      ).onOk(async () => {
        this.$root.withLoading(() => this.store.remove(synapse.serial))
      })
    }    
  }
}