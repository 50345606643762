const MODES = {
  IN: 0b1000,
  OUT: 0b0100,
  IO_DIGITAL: 0b0010,
  IO_ANALOG: 0b0001,
  IO_PWM: 0b0011
}

const MODE_MAP = {
  0b1000: 'IN',
  0b0100: 'OUT',
  0b0010: 'IO_DIGITAL',
  0b0001: 'IO_ANALOG',
  0b0011: 'IO_PWM'
}

export { MODES, MODE_MAP }